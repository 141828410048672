import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DataService {
  headers;
  baseURL;
  isDeviceSet = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.setHeaders();
  }

  setHeaders() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.headers = new HttpHeaders().set('access_token', user.access_token);
    }
    const mac = localStorage.getItem('mac');
    if (!!mac) {
      this.isDeviceSet.next(true);
    }
  }

  getGatewayData(mac) {
    return this.http.get(
      this.getBaseUrl() + '/deviceControl/api/v1/gateways/internal/' + mac,
      { headers: this.headers }
    );
  }

  getHomeData(homeId, homeGwId) {
    return this.http.get(
      this.getBaseUrl() +
        '/acl/api/v1/users/homes/internal/' +
        homeId +
        '/gateways/' +
        homeGwId +
        ' ',
      { headers: this.headers }
    );
  }

  getVersionsData(homeId, homeGwId) {
    return this.http.get(
      this.getBaseUrl() +
      '/eventHandler/api/v1/data/' +
      homeId +
      '/gateways/' +
      homeGwId +
      '/versionStatus',
      { headers: this.headers }
    );
  }

  getDevicesData(homeGwMac) {
    return this.http.get(
      this.getBaseUrl() +
      '/deviceControl/api/v1/gateways/internal/' + homeGwMac + '/devices',
      { headers: this.headers }
    );
  }

  getLastUnclaimTime(homeGwMac) {
    return this.http.get(
      this.getBaseUrl() +
      '/deviceControl/api/v1/gateways/internal/' + homeGwMac,
      { headers: this.headers }
    );
  }

  getUserData(userId) {
    return this.http.get(
      this.getBaseUrl() + '/acl/api/v1/users/internal/' + userId,
      { headers: this.headers }
    );
  }

  unclaimHub(homeGwMac) {
    return this.http.delete(
      this.getBaseUrl() +
      '/deviceControl/api/v1/gateways/internal/' + homeGwMac,
      { headers: this.headers }
    );
  }

  private getBaseUrl() {
    const env = localStorage.getItem('ENV');

    if (!env) {
      localStorage.setItem('ENV', 'fieldtest');
    }

    // console.log('ENV ' + env);
    if (env === 'dev') {
      return (this.baseURL = 'https://dev.smarthome.aws.rehau.cloud');
    } else if (env === 'prod') {
      return (this.baseURL = 'https://prod.smarthome.aws.rehau.cloud');
    }
    return (this.baseURL = 'https://fieldtest.smarthome.aws.rehau.cloud');
  }
}
