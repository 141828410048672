import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CidaasProvider } from './cidaasProvider';
import { generate } from 'rxjs';
import { Oauth } from 'ionic-cordova-oauth/dist/oauth';
import { UserService, ITokenEndpointBody } from '../user.service';
import { OauthCordova, OauthBrowser } from 'ionic-cordova-oauth';

@Component({
  selector: 'app-cidaas-login',
  templateUrl: './cidaas-login.component.html',
  styleUrls: ['./cidaas-login.component.scss'],
})
export class CidaasLoginComponent implements OnInit {
  constructor(
    private router: Router,
    private device: DeviceDetectorService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const isLoggedIn = await this.userService.isLoggedIn();

    if (isLoggedIn) {
      this.router.navigate(['mac']);
    }
  }

  login() {
    const provider = new CidaasProvider({
      clientId: CidaasProvider.client_ID,
      appScope: ['email', 'roles', 'profile', 'offline_access'],
      responseType: 'code',
      redirectUri: 'https://support.smarthome.aws.rehau.cloud/callback',
      // local
      //redirectUri: 'http://localhost:4200/callback',
      // dev environment S3
      //redirectUri: 'http://supportpage-dev.s3-website-eu-west-1.amazonaws.com/callback',
      code_challenge: '9235487394587-xcode',
      code_challenge_method: 'S256',
    });

    const pkceBody: ITokenEndpointBody = {
      grant_type: 'authorization_code',
      client_id: provider.options.clientId,
      redirect_uri: provider.options.redirectUri,
      code_verifier: CidaasProvider.base64URLEncode(
        provider.options.code_challenge
      ),
    };
    let oauth: Oauth;
    if (this.device.isDesktop) {
      oauth = new OauthBrowser();
    } else {
      oauth = new OauthCordova();
    }

    oauth
      .logInVia(provider, {
        closebuttoncolor: '#dd0060',
        hardwareback: 'no',
        hidenavigationbuttons: 'no',
        hideurlbar: 'yes',
        navigationbuttoncolor: '#dd0060',
        toolbarcolor: '#f7f7f7',
      })
      .then(
        (success) => {
          console.log(success);
          pkceBody.code = success['code'];
          this.userService.oAuthCallback(pkceBody).then(async () => {
            this.router.navigate(['mac']);
          });
        },
        (error) => {
          console.log('login-error: ' + error);
        }
      );
  }
}
