<div class="background ">
    <div class="box">
        <h1>Rehau Login</h1>



        <button (click)="login()" class="cta-button" type="submit">
              <label class="login-btn-label">Login Now</label>
            </button>
    </div>
</div>