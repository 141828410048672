<div class="rehau-logo"> <img src="assets/images/rehau-logo.svg-data.svg" height="50px" />
    <span class="logout" *ngIf="isLoggedIn" (click)="logout()">Logout</span>
</div>
<div class="headline-wrapper">
  <div class="headline">
    <h1>RE.HUB Service</h1>
    <h2>Get helpful Information about RE.HUB, RE.GUARD and Smart Guard Devices. </h2>
  </div>
  <div *ngIf="!isMac && isLoggedIn" class="robot-wrapper">
    <h2>RE.HUB Services Uptime Dashboard:
      <span class="uptime-link" (click)="goToUptime()">click here</span>
    </h2>
    <h2>Uptime Dashboard password: r3h4pd4shb04rd</h2>
  </div>
</div>
