import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, IAuthObserver } from '../user.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, IAuthObserver {
  constructor(
    private router: Router,
    private userService: UserService,
    private dataService: DataService
  ) {}

  observerId = 1;
  isLoggedIn;
  isMac;

  onAuthChange(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn;
  }
  ngOnInit(): void {
    this.userService.subscribe(this);
    this.isLoggedIn = JSON.parse(localStorage.getItem('user'));
    this.dataService.isDeviceSet.subscribe(isDeviceSet => this.isMac = isDeviceSet);
  }

  async logout() {
    await this.revokeToken();
    localStorage.clear();
    this.router.navigate(['']);
    this.isLoggedIn = false;
  }

  private async revokeToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user.access_token;
    try {
      await this.userService.revokeToken(token);
    } catch (err) {
      console.log('revoke token error', err);
    }
  }

  public goToUptime() {
    window.open('https://rehub.uptimerobot.nv21.io/', '_blank');
  }
}
