import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { UserService } from '../user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private router: Router,
    private userService: UserService
  ) {}

  mac;
  error;
  isLoading: boolean;

  ngOnInit(): void {
    this.init();

    if (localStorage.getItem('data')) {
      this.router.navigate(['home']);
    }
  }

  async init() {
    const isLoggedIn = await this.userService.isLoggedIn();

    if (!isLoggedIn) {
      this.router.navigate(['']);
    } else {
      this.dataService.setHeaders();
    }
  }

  onEnvChange(val) {
    localStorage.setItem('ENV', val);
  }

  getGatewayData() {
    this.isLoading = true;
    this.error = null;

    this.dataService.getGatewayData(this.mac).subscribe(
      (data) => {
        localStorage.setItem('data', JSON.stringify(data));
        localStorage.setItem('mac', JSON.stringify(this.mac));
        this.router.navigate(['home']);
      },
      (err) => {
        this.error = err;
        this.mac = null;
        this.isLoading = false;
      }
    );
  }
}
