import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { UserService } from '../user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private router: Router,
    private dataService: DataService,
    private cdRef: ChangeDetectorRef,
    private userService: UserService
  ) {}

  gatewayData;
  homeData;
  versionData;
  reguardData;
  sensorsData;
  lastUnclaimTime;
  unclaimMessage = 'Unlclaiming takes place immediately without confirmation, and cannot be reversed!';
  userId;
  userData;
  error;
  homeError;
  unclaimError;
  userError;
  versionError;
  deviceError;
  userCredentials;
  uptime;
  lastUpdate;
  updateTimeInSeconds = 20;
  secondsSinceLastReboot;
  mac;

  isOnline = false;
  isClaimed = false;

  timer;

  ngOnInit(): void {
    if (localStorage.getItem('mac')) {
      this.mac = JSON.parse(localStorage.getItem('mac'));
    }
    this.initialize();

    this.timer = setInterval(() => {
      this.initialize();
    }, this.updateTimeInSeconds * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  public unclaimRehub() {
    this.dataService
      .unclaimHub(this.mac)
      .pipe(take(1))
      .subscribe(
        data => this.unclaimMessage = data['message'],
        err => {
          this.unclaimError = true;
          this.unclaimMessage = err.error.error;
        }
      );
  }

  private getVersionsData() {
    this.dataService
      .getVersionsData(this.gatewayData.homeId, this.gatewayData.homeGwId)
      .subscribe(
        data => this.versionData = JSON.parse(JSON.stringify(data[0].eventData)),
        err => {
          console.error(err);
          this.versionError = err;
        }
      );
  }

  private getDevicesData() {
    this.dataService
      .getDevicesData(this.mac)
      .pipe(take(1))
      .subscribe(
        ({ reguard, waterSensors }: any) => {
          this.reguardData = JSON.parse(JSON.stringify(reguard));
          this.sensorsData = JSON.parse(JSON.stringify([...waterSensors]));
        },
        err => {
          console.error(err);
          this.deviceError = err;
        }
      );
  }

  private getLastUnclaimedTime() {
    this.dataService
      .getLastUnclaimTime(this.mac)
      .subscribe(
        data => {
          this.lastUnclaimTime = data['lastUnclaimTime'];
        },
        err => {
          console.error(err);
        }
      );
  }

  public getDate(d, isTimestamp?: boolean): string {
    const date = isTimestamp ? new Date(Number(d)) : new Date(d);

    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  }

  private getUserData() {
    this.dataService.getUserData(this.userId).subscribe(
      (data) => {
        localStorage.setItem('userdata', JSON.stringify(data));
        this.userData = JSON.parse(JSON.stringify(data));

        const gateways = this.userData.homes[
          Object.keys(this.userData.homes)[0]
        ];

        this.userCredentials =
          gateways.gateways[Object.keys(gateways.gateways)[0]].userCredentials;
      },
      (err) => {
        console.error(err);
        this.userError = err;
      }
    );
  }

  async initAuth() {
    const isLoggedIn = await this.userService.isLoggedIn();

    if (!isLoggedIn) {
      this.router.navigate(['']);
    }
  }

  public initialize() {
    this.initAuth();
    if (!localStorage.getItem('data')) {
      this.router.navigateByUrl('');
    } else {
      // this.userData = null;
      // this.homeData = null;
      this.gatewayData = JSON.parse(localStorage.getItem('data'));
      this.calculateUptime();
      this.calculateLastStatusUpdate();
      this.getState();

      // this.getUserData();
      this.getVersionsData();
      this.getLastUnclaimedTime();
      this.getDevicesData();
      this.getGatewayData();
    }
  }

  private calculateUptime() {
    this.uptime =
      new Date(
        // tslint:disable-next-line: radix
        parseInt(this.gatewayData.lastStatusTime)
      ).getTime() - this.gatewayData.upTime;
  }

  private calculateLastStatusUpdate() {
    this.lastUpdate = JSON.parse(this.gatewayData.lastStatusTime);
  }

  private getGatewayData() {
    this.dataService.getGatewayData(this.gatewayData.id).subscribe(
      (data) => {
        localStorage.setItem('data', JSON.stringify(data));
        this.gatewayData = JSON.parse(localStorage.getItem('data'));
      },
      (err) => {
        this.error = err;
      }
    );
  }

  public timeSince(date) {
    const now = new Date();

    const seconds = Math.floor(
      (now.getTime() - new Date(date).getTime()) / 1000
    );

    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + ' years ago';
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + ' months ago';
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + ' days ago';
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + ' hours ago';
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval + ' minutes ago';
    }
    return Math.floor(seconds) + ' seconds ago';
  }

  public getState() {
    const now = new Date();
    const secondsSinceUpdate = Math.floor(
      (now.getTime() - new Date(this.lastUpdate).getTime()) / 1000
    );

    this.isClaimed = this.gatewayData.homeId && this.gatewayData.homeGwId;

    this.secondsSinceLastReboot = Math.floor(
      (now.getTime() - new Date(this.uptime).getTime()) / 1000
    );

    this.isOnline = false;

    if (this.lastUpdate) {
      if (secondsSinceUpdate < 600) {
        this.isOnline = true;
      }

      return (
        '✅ RE.HUB is ' +
        (this.isOnline ? 'online' : 'offline') +
        ' and is ' +
        (this.isClaimed
          ? '✅ claimed'
          : '❌not claimed and is ' +
            (this.secondsSinceLastReboot < 3600
              ? '✅claimable'
              : '❌not claimable'))
      );
    }
  }

  logout() {
    this.router.navigateByUrl('mac');
    const user = JSON.parse(localStorage.getItem('user'));
    this.dataService.isDeviceSet.next(false);
    localStorage.clear();
    localStorage.setItem('user', JSON.stringify(user));
  }

  isObject(val): boolean {
    return typeof val === 'object';
  }
}
