<div class="background ">
    <div class="box">
        <h1>Search for Device</h1>
        <span *ngIf="error" class="error-message">{{
              error.error.message | json
            }}</span>
        <input (keyup.enter)="getGatewayData()" autofocus [(ngModel)]="mac" type="text" placeholder="Gateway Mac" autocomplete="on" />
        <select name="env" id="env" (change)="onEnvChange($event.target.value)">
            <option value="fieldtest">Fieldtest</option>
            <option value="dev">Development</option>
            <option value="prod">Production</option>
        </select>
        <div class="login-wrapper">
            <button [disabled]="!mac" (click)="getGatewayData()" class="cta-button" type="submit">
                <label class="login-btn-label">Login</label>
            </button>
            <span *ngIf="isLoading" class="spinner-container">
                <i class="fas fa-spinner spin"></i>
            </span>
        </div>
    </div>
</div>
