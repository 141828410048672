<div class="content">
    <p class="changeDevice" (click)="logout()">Change Device</p>
    <h1 class="pl-20">RE.HUB Information</h1>
    <div class="flex mt-10 pl-20 mobile-column">
        <div class="flex">
            <img src="assets/images/rehub.svg">
            <div class="flex column ml-20 w-120 ">
                <span class="mini">Last Reboot</span>
                <span>{{ timeSince(uptime) }}</span>
                <span class="mini">Last Status Update</span>
                <span [ngClass]="{'red': !isOnline}">{{timeSince(lastUpdate)}} <i *ngIf="!isOnline" class="fas fa-exclamation-triangle"></i></span>
            </div>
        </div>

        <div class="flex">
            <div class="online-indicator">
                <img [src]="isOnline ? 'assets/images/online.svg' : 'assets/images/offline.svg'">

            </div>
            <span class="self-center">{{this.isOnline ? 'ONLINE': 'OFFLINE'}}</span>
        </div>
        <div class="flex">
            <div class="online-indicator" *ngIf="isOnline">
                <img *ngIf="!isClaimed &&  secondsSinceLastReboot < 3600 " src="assets/images/setup-ready.svg">
                <img *ngIf="!isClaimed &&  secondsSinceLastReboot > 3600 " src="assets/images/setup-blocked.svg">
                <img *ngIf="isClaimed" src="assets/images/linked.svg">


            </div>
            <span class="self-center" *ngIf="isOnline">{{!isClaimed &&  secondsSinceLastReboot < 3600 ? 'READY FOR SETUP' : !isClaimed &&  secondsSinceLastReboot > 3600 ?  'SETUP NOT READY' : 'LINKED TO USER'}}</span>
        </div>
    </div>

    <div class="flex mt-10 pl-20 mobile-column unclaim-row">
        <div class="flex unclaim-container">
            <button (click)="unclaimRehub()"
                    class="cta-button"
                    type="submit">
              <label class="login-btn-label">Unclaim</label>
            </button>
            <span *ngIf="unclaimMessage"
                  [ngClass]="{ 'red-message': unclaimError }"
                  class="unclaim-message">
                  {{ unclaimMessage }}
                </span>
          </div>
    </div>

    <div class="selects">
        <details>
            <summary class="paired-summary" [ngClass]="{ 'no-data': !gatewayData }"> Gateway Information
            </summary>
            <div class="padding-15">
                <div class="row">
                    <span class="key">Box Id</span>
                    <span class="value">{{ gatewayData.boxId || '---'  }}</span>
                </div>
                <div class="row">
                    <span class="key">Local IP-Address</span>
                    <span class="value">{{  gatewayData.localIp || '---'   }}</span>
                </div>
                <div class="row">
                    <span class="key">Mac Adress </span>
                    <span class="value">{{  this.mac || '---'   }}</span>
                </div>
                <div class="row">
                    <span class="key">Type</span>
                    <span class="value">{{  gatewayData.type || '---'   }}</span>
                </div>
                <div class="row">
                    <span class="key">HomeGwId</span>
                    <span class="value">{{ gatewayData.homeGwId || '---' }}</span>
                </div>
                <div class="row" *ngIf="lastUnclaimTime">
                    <span class="key">Last time unclaimed</span>
                    <span class="value">{{ getDate(lastUnclaimTime) || '---'  }}</span>
                </div>
            </div>
        </details>

        <details *ngIf="versionData && !versionError">
            <summary class="paired-summary"> Version Information
            </summary>
            <div class="padding-15">
                <div class="row">
                    <span class="key">RE.HUB Software</span>
                    <span class="value">{{ versionData.zwayVersion || '---'  }}</span>
                </div>
                <div class="row">
                    <span class="key">RE.GUARD Firmware</span>
                    <span class="value">{{ versionData.firmware1Version || '---'  }}</span>
                </div>
                <div class="row">
                    <span class="key">RE.GUARD Z-Wave Version</span>
                    <span class="value">{{ versionData.leackageApplVersion || '---'  }}</span>
                </div>
                <div class="row">
                    <span class="key">Module Version</span>
                    <span class="value">{{ versionData.moduleVersion || '---'  }}</span>
                </div>
            </div>
            <span class="info-block">Versions are only updated once every 24 hours. Recent updates may not yet be reflected here.</span>
        </details>
        <details *ngIf="reguardData">
            <summary class="paired-summary">RE.GUARD Device</summary>
            <div class="padding-15">
                <span class="row">
                  <span class="key">Device name</span>
                  <span class="value">{{ reguardData.deviceName }}</span>
                </span>
                <span class="row">
                  <span class="key">Device node id</span>
                  <span class="value">{{ reguardData.nodeId }}</span>
                </span>
                <span class="row">
                  <span class="key">Last update</span>
                  <span class="value">{{ getDate(reguardData.lastUpdate, true) }}</span>
                </span>
                <span class="row">
                  <span class="key">Last error code</span>
                  <span class="value">{{ reguardData.lastErrorCode }}</span>
              </span>
              <span class="row">
                  <span class="key">RSSI value</span>
                  <span class="value">{{ reguardData.rssi }}</span>
              </span>
            </div>
        </details>
        <ng-container *ngIf="sensorsData && sensorsData.length">
            <details *ngFor="let sensor of sensorsData">
                <summary class="paired-summary">{{ sensor.deviceName }} (water sensor)</summary>
                <div class="padding-15">
                    <span class="row">
                        <span class="key">Battery level</span>
                        <span class="value">{{ sensor.batteryLevel }}</span>
                    </span>
                    <span class="row">
                        <span class="key">Node Id</span>
                        <span class="value">{{ sensor.nodeId }}</span>
                    </span>
                    <span class="row">
                        <span class="key">Temperature</span>
                        <span class="value">{{ sensor.temperature }}</span>
                    </span>
                    <span class="row">
                        <span class="key">RSSI</span>
                        <span class="value">{{ sensor.rssi }}</span>
                    </span>
                    <span class="row">
                        <span class="key">Last seen</span>
                        <span class="value">{{ getDate(sensor.lastUpdate, true) }}</span>
                    </span>
                </div>
            </details>
            </ng-container>
        <ng-container *ngIf="reguardData || (sensorsData && sensorsData.length)">
          <span class="info-block">Shown are devices that were active within the last 7 days. For the most accurate device list, please check the expert view page.</span>
        </ng-container>

        <details *ngIf="userData && !userError">
            <summary class="paired-summary" [ngClass]="{ 'no-data': !gatewayData }"> User Information
            </summary>
            <div class="padding-15">
                <span class="key pl-10">User Credentials</span>
                <div class="row" *ngFor="let item of userCredentials">
                    <span class="key"> {{ item.user || '---'  }}</span>
                    <span class="value">{{ item.password || '---'  }}</span>
                </div>
                <ng-container *ngFor="let item of userData | keyvalue">
                    <div class="row" *ngIf="item.key !== 'homes'">
                        <span class="key">{{ item.key  }}</span>
                        <span class="value">{{ item.value || '---'  }}</span>
                    </div>
                </ng-container>
            </div>
        </details>
    </div>
</div>
