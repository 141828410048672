import {
  OAuthProvider,
  IOAuthOptions,
} from '../../../node_modules/ionic-cordova-oauth/dist/provider';
import * as shajs from 'sha.js';

export interface IcidaasProviderOptions extends IOAuthOptions {
  authType?: string;
  code_challenge?: string;
  code_challenge_method?: string;
  nonce?: string;
  viewType?: string;
}

export class CidaasProvider extends OAuthProvider {
  static baseURL = 'https://accounts.rehau.com';
  static tokenEndpoint = CidaasProvider.baseURL + '/token-srv/token';
  static client_ID = 'fb568d9a-efcf-46d9-9363-4e9faf7df0b9';
  static revokeUrl = CidaasProvider.baseURL + '/session/end_session';

  options: IcidaasProviderOptions;
  protected authUrl = CidaasProvider.baseURL + '/authz-srv/authz';
  protected defaults: Object = {
    responseType: 'code',
  };

  constructor(options: IcidaasProviderOptions = {}) {
    super(options);

    if (!options.appScope || options.appScope.length <= 0) {
      throw new Error(`A ${this.name} app scope must exist`);
    }
  }

  static base64URLEncode(str): string {
    return str
      .toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }

  static sha256(buffer) {
    return shajs('sha256').update(buffer).digest();
  }

  private removeParam(key, sourceURL) {
    let rtn = sourceURL.split('?')[0],
      param = '',
      params_arr = [];
    const queryString =
      sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
    if (queryString !== '') {
      params_arr = queryString.split('&');
      for (let i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split('=')[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }
      rtn = rtn + '?' + params_arr.join('&');
    }
    return rtn;
  }

  private addParam(key, sourceURL) {
    sourceURL += `&scope=`;
    sourceURL += `${this.options.appScope.join(' ')}`;
    return sourceURL;
  }

  protected optionsToDialogUrl(options) {
    let url = super.optionsToDialogUrl(options);
    url = this.removeParam('scope', url);
    url = this.addParam('scope', url);
    if (options.authType) {
      url += `&auth_type=${options.authType}`;
    }
    if (options.nonce) {
      url += `&nonce=${options.nonce}`;
    }
    if (options.code_challenge_method === 'plain') {
      url += `&code_challenge=${options.code_challenge}&code_challenge_method=${options.code_challenge_method}`;
    }
    if (options.code_challenge_method === 'S256') {
      url += `&code_challenge=${CidaasProvider.base64URLEncode(
        CidaasProvider.sha256(options.code_challenge)
      )}&code_challenge_method=${options.code_challenge_method}`;
    }
    if (options.viewType) {
      url += `&view_type=${options.viewType}`;
    }
    console.log('Calling URL: ' + url);
    return url;
  }
}
